<template>
    <div class="content-block" :class="classModifier">
        <BlockDefault v-if="data.ctype === 'textmedia'" :block="data" :seamless="seamless" />
        <BlockHeader v-else-if="data.ctype === 'header'" :block="data" :seamless="seamless" />
    </div>
</template>

<script>
import BlockDefault from "./BlockDefault";
import BlockHeader from "./BlockHeader";

export default {
	name: "MasterBlock",
	data() {
		return {};
	},
	props: {
		data: {
			type: Object,
			required: true
		},
		seamless: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		classModifier() {
			return (this.data.ctype) ? 'content-block--' + this.data.ctype : '';
		}
	},
	components: {
		BlockDefault,
		BlockHeader
	},
	methods: {}
};
</script>

<style scoped>

</style>