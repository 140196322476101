<template>
    <div class="block block--text" :class="{'block--seamless' : seamless}" :id="block.uid">
        <figure v-if="block.images.length > 0" class="block-banner">
            <img :src="block.images[0].url" alt="">
        </figure>
        <header class="block-header" v-if="block.header || block.subheader">
            <h2 class="block-title" v-html="block.header"></h2>
            <p class="block-title like-h3" v-text="block.subheader"></p>
        </header>
        <div class="block-content" v-prepend="block.bodytext">
            <div v-html="block.bodytext"></div>
            <template v-if="block.youtube.length">
                <div class="video-wrap">
                    <youtube v-if="block.youtube.length" class="block-video" :player-vars="{showinfo : false}" :video-id="block.youtube[0]" ref="youtube"></youtube>
                </div>
            </template>

<!--            <download-list :files="block.downloads" v-if="block.downloads.length" />-->

            <gallery v-if="gallery" :images="gallery" :columns=" Math.min( gallery.length, 3 ) "></gallery>

        </div>
    </div>
</template>

<script>
import KappGallery from '../Subparts/KappGallery';
import Youtube from "vue3-youtube";

export default {
	name: "BlockDefault",
	data() {
		return {};
	},
	filters: {},
	components: {
		'gallery': KappGallery,
        Youtube
	},
	directives: {
		prepend: {
			inserted: function ( el, binding ) {
				el.insertAdjacentHTML('afterBegin', (binding.value));
			}
		}
	},
	props: {
		block: {
			type: Object
		},
		seamless: {
			type: Boolean
		}
	},
	computed: {
		gallery: function () {
			let gallery = [];

			if ( this.block.images.length > 1 ) {
				gallery = this.block.images.slice(1);
				gallery = gallery.map(( img ) => img.url);
			}

			return gallery;

		}
	},
	methods: {
		// handleClicks: function ( $event ) {
		// 	if ( $event.target.tagName === 'A' ) {
		// 		if ( $event.target.href.toString().substr(-3) === 'pdf' ) {
		// 			$event.preventDefault();
		// 			Events.$emit('modal:open', $event.target.href);
		// 		}
		// 	}
		// }
		// handleClicks ($event) {
		// 	const { target } = $event;
		// 	// console.log( $event, target , target.matches(".block-content a:not([href*='://'])") , target.href );
		// 	// handle only links that occur inside the component and do not reference external resources
		// 	if (target && target.matches(".block-content a:not([href*='://'])") && target.href) {
		// 		// some sanity checks taken from vue-router:
		// 		// https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
		// 		const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = $event;
		//
		// 		// don't handle with control keys
		// 		if (metaKey || altKey || ctrlKey || shiftKey) return;
		// 		// don't handle when preventDefault called
		// 		if (defaultPrevented) return;
		// 		// don't handle right clicks
		// 		if (button !== undefined && button !== 0) return;
		// 		// don't handle if `target="_blank"`
		// 		if (target && target.getAttribute) {
		// 			const linkTarget = target.getAttribute('target');
		// 			if (/\b_blank\b/i.test(linkTarget)) return;
		// 		}
		// 		// don't handle same page links/anchors
		// 		const url = new URL(target.href);
		// 		const to = url.pathname;
		// 		if (window.location.pathname !== to && $event.preventDefault) {
		// 			$event.preventDefault()
		// 			this.$router.push(to)
		// 		}
		// 	}
		// 	else {
		// 		$event.preventDefault();
		// 		window.open( 'goto://DeviceBrowser?url=' + target.href );
		// 	}
		// }
	},
	mounted() {
		this.$el.addEventListener('click', this.handleClicks);
	}
};
</script>

<style scoped>
.gallery {
    margin-left: -15px;
    margin-right: -15px;
}
</style>
