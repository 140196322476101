<template>
    <transition-group name="fade" tag="div" mode="out-in" id="infocenter">
        <h1 class="page-title" v-html="contentTitle" key="title"/>
        <div class="content-block" v-if="content.length > 0" key="infocenterContent">

            <template v-if="content.length">
                <MasterBlock v-for="(contentBlock,uid) in content" :data="contentBlock" :key="uid + '_' + contentBlock.title"></MasterBlock>
            </template>
        </div>
    </transition-group>
</template>

<script>
import MasterBlock from '@/components/Blocks/MasterBlock';
import debounce from 'lodash.debounce';
import api, { ApiDefaults } from "@/utils/api_call";


export default {
	name: "ContentPage",
    components:{
		MasterBlock
    },
	data() {

		return {
			subpages: false,
			content: false,
			places: false,
			articles: false,
			articleCategories: [],
			placeCategories: [],
			pageTitle: 'Infocenter',
			contentTitle: 'Infocenter',
			transition: ''
		};
	},

	methods: {
		fetchData: debounce(function () {

			let sourceId = 10;

			if ( this.$route.params.slug && this.$route.params.slug !== 'alles' ) {
				sourceId = this.$route.params.slug.split('_');
				sourceId = sourceId[ 0 ];
			}

			let apiParams = Object.assign({}, ApiDefaults, {params: {id: sourceId}});
			api(apiParams)
				.then(response => {
					if ( response.status === 200 ) {
						this.subpages = false;
						this.content = false;
						this.places = false;
						this.articles = false;

						if ( Object.prototype.hasOwnProperty.call(response.data, 'menu') ) {
							let pageLinks = response.data.menu.pages;
							this.subpages = pageLinks;
						}

						if ( Object.prototype.hasOwnProperty.call(response.data, 'ttContent') ) {
							let content = response.data.ttContent.content;
							this.content = content;
						}

						if ( Object.prototype.hasOwnProperty.call(response.data, 'news') ) {
							this.articles = response.data.news.content.map(( item ) => {
														item.categories = [ parseInt(item.categories) ];
														item.visible = true;
														return item;
													})
													.sort(( a, b ) => b.datetime - a.datetime);

							this.articleCategories = Object.values(response.data.news.categories);

						}

						if ( response.data.page.pageTitle !== 'Infocenter' ) {
							this.contentTitle = response.data.page.pageTitle;
						}
						else {
							this.contentTitle = 'Infocenter';
						}
					}
				});
		}, 125)
	},
	beforeMount() {
		this.fetchData();
	},
	watch: {
		'$route': function (to) {
			if( to.name === 'Infocenter' || to.name === 'infocenter_contentpage' ) {
				this.fetchData();
			}
		}
	}
};
</script>

<style scoped>

</style>