<template>
    <div class="block block--header" :class="{'block--seamless' : seamless}" :id="block.uid">
        <h2 v-html="block.header"></h2>
        <p v-text="block.subheader"></p>
    </div>
</template>

<script>
	// import Downloadlist from "../subparts/Downloadlist";
	// import KappGallery from '../subparts/KappGallery';

	export default {
		name: "BlockHeader",
		data() {
			return {};
		},
		filters: {},
		components: {
			// 'download-list': Downloadlist,
			// 'gallery': KappGallery
		},
		directives: {
			prepend: {
				inserted: function ( el, binding ) {
					el.insertAdjacentHTML('afterBegin', (binding.value));
				}
			}
		},
		props: {
			block: {
				type: Object
			},
			seamless : {
				type : Boolean
			}

		},
		computed: {
			gallery: function () {
				let gallery = [];

				if ( this.block.images.length > 1 ) {
					gallery = this.block.images.slice(1);
					gallery = gallery.map(( img ) => img.url);
				}

				return gallery;

			}
		},
		methods: {
			// handleClicks ($event) {
			// 	const { target } = $event;
			// 	// console.log( $event, target , target.matches(".block-content a:not([href*='://'])") , target.href );
			// 	// handle only links that occur inside the component and do not reference external resources
			// 	if (target && target.matches(".block-content a:not([href*='://'])") && target.href) {
			// 		// some sanity checks taken from vue-router:
			// 		// https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
			// 		const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = $event;
			//
			// 		// don't handle with control keys
			// 		if (metaKey || altKey || ctrlKey || shiftKey) return;
			// 		// don't handle when preventDefault called
			// 		if (defaultPrevented) return;
			// 		// don't handle right clicks
			// 		if (button !== undefined && button !== 0) return;
			// 		// don't handle if `target="_blank"`
			// 		if (target && target.getAttribute) {
			// 			const linkTarget = target.getAttribute('target');
			// 			if (/\b_blank\b/i.test(linkTarget)) return;
			// 		}
			// 		// don't handle same page links/anchors
			// 		const url = new URL(target.href);
			// 		const to = url.pathname;
			// 		if (window.location.pathname !== to && $event.preventDefault) {
			// 			$event.preventDefault()
			// 			this.$router.push(to)
			// 		}
			// 	}
			// 	else {
			// 		$event.preventDefault();
			// 		window.open( 'goto://DeviceBrowser?url=' + target.href );
			// 	}
			// }
		}
	};
</script>

<style scoped>
    .gallery {
        margin-left: -15px;
        margin-right: -15px;
    }
</style>